<template>
  <div class="iotplt-content-box">
    <div class="iotplt-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showNewModal">新增地址</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :scroll="{ x: 'max-content' }"
      :pagination="false"
      @change="sortChange"
      row-key="id"
    >
      <div slot="is_default" slot-scope="is_default">
        <span :style="{color:'#46a6ff'}">{{ is_default ? '默认' : '' }}</span>
      </div>

      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a
            @click="setDefault(record.id)"
            v-if="isHasSetDefaultPermission && record.is_default==false"
          >设为默认</a>

          <a @click="showEditModal(record.id)" v-if="isHasEditPermission">编辑</a>

          <a @click="showDeleteModal(record.id)" v-if="isHasDeletePermission">删除</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 编辑模态框 -->
    <edit-receive-address
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      @completed="fetchData"
    />

    <!-- 新增模态框 -->
    <new-receive-address
      v-if="isShowNewModel"
      :visible.sync="isShowNewModel"
      @completed="fetchData"
    />

  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { DeleteReceiveAddress, findReceiveAddresses, SetReceiveAddressDefault } from '@/api/receive_address'
import Pagination from '@/components/Pagination'

export default {
  name: 'ReceiveAddressesList',
  components: {
    Pagination,
    EditReceiveAddress: () => import('@/views/receive_addresses/Edit'),
    NewReceiveAddress: () => import('@/views/receive_addresses/New')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowNewModel: false, // 是否显示新增表单弹窗
      editingId: 0, // 正在编辑的记录id
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '',
          dataIndex: 'is_default',
          scopedSlots: { customRender: 'is_default' },
          fixed: 'left',
          width: 100
        },
        {
          title: '收货人',
          dataIndex: 'receiver'
        },
        {
          title: '联系电话',
          dataIndex: 'phone_number'
        },
        {
          title: '所在地区',
          dataIndex: 'region_display'
        },
        {
          title: '邮编',
          dataIndex: 'post_code'
        },
        {
          title: '详细地址',
          dataIndex: 'address_detail',
          width: 300
        },
        {
          title: '操作',
          dataIndex: 'actions',
          key: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },

    isHasEditPermission() {
      return hasPermission('customer/receive_addresses/edit')
    },

    isHasSetDefaultPermission() {
      return hasPermission('customer/receive_addresses/set_default')
    },

    isHasDeletePermission() {
      return hasPermission('customer/receive_addresses/delete')
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    // 显示编辑弹窗
    showEditModal(recordId) {
      this.editingId = recordId
      this.isShowEditModal = true
    },

    // 显示新增弹窗
    showNewModal() {
      this.isShowNewModel = true
    },

    // 显示删除弹窗
    showDeleteModal(recordId) {
      const thisIndex = this
      this.$confirm({
        title: '提示',
        content: '确定删除地址吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          DeleteReceiveAddress(recordId).then((res) => {
            if (res.code === 0) {
              thisIndex.fetchData()
            }
          })
        },
        onCancel() {
        }
      })
    },

    // 设置默认地址
    setDefault(recordId) {
      this.loading = true
      SetReceiveAddressDefault(recordId).then((res) => {
        if (res.code === 0) {
          this.fetchData()
        }
      })
    },

    // 排序
    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findReceiveAddresses(
        Object.assign({ agent_id: this.$store.getters.userAgentId }, this.query, this.sort)
      ).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    }
  }
}
</script>

<style>

</style>
